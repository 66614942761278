import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { DynamicPages } from './dynamicPages'
import { RoutingMiddleware } from 'feature-router'
import { WarmupDataEnricherSymbol } from 'feature-warmup-data'
import { DynamicPagesWarmupDataEnricher } from './dynamicPagesWarmupDataEnricher'
import { DynamicPagesSymbol } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind(RoutingMiddleware.Dynamic, DynamicPagesSymbol).to(DynamicPages)

	if (!process.env.browser) {
		bind(WarmupDataEnricherSymbol).to(DynamicPagesWarmupDataEnricher)
	}
}
